import React, { Component } from 'react';

class LinkBlock extends Component {
    constructor(props){
        super(props);
        this.state = {
            text: this.props.text,
            color: this.props.color,
            href: this.props.href
        }
    }
    render() {
        return (
            <div className="content--link">
                <a
                  className={"content--link content--highlight-" + this.props.color}
                  href={this.props.href}
                >
                  {this.props.text}
                </a>
            </div>
        );
    }
}

export default LinkBlock;